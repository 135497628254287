








import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'

@Component
export default class ClaimIndex extends Vue {
  transitionName = constants.transitionNames.SLIDE_LEFT

  created () {
    this.$router.beforeEach((to, from, next) => {
      const isNextScreen = from.meta?.step > to.meta?.step
      this.transitionName = isNextScreen ? constants.transitionNames.SLIDE_RIGHT : constants.transitionNames.SLIDE_LEFT
      next()
    })
  }
}
