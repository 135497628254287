















































































import { Component, Vue } from 'vue-property-decorator'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import constants from '@/constants'
import FluidCheckbox from '@/components/facts/FluidCheckbox.vue'
import Warning from '@/components/Warning.vue'
import AonButton from '@/components/AonButton.vue'
import ImportantNote from '@/components/ImportantNote.vue'
import store from '@/store'
import { ACTION_CLAIM_CONSENT, ACTION_RESET_CLAIM_STATE } from '@/store/modules/claim/actionTypes'
import { Getter } from 'vuex-class'

@Component({
  components: {
    BackDashboardButton,
    Warning,
    ImportantNote,
    AonButton,
    FluidCheckbox
  }
})
export default class BeforeClaim extends Vue {
  @Getter('app/newTermUse') newTermUse!: string

  @Getter('app/extraWaitingMessage')
  extraWaitingMessage!: boolean

  accepted = false

  async proceed() {
    if (this.accepted) {
      await store.dispatch(ACTION_RESET_CLAIM_STATE)
      await store.dispatch(ACTION_CLAIM_CONSENT, this.accepted)

      this.$router.replace({ name: constants.routeNames.CLAIM_LIST })
    }
  }

  openTermsOfUse() {
    this.$modal.show(this.newTermUse ? constants.modalNames.NEW_TERM_OF_USE_MODAL : constants.modalNames.TERM_OF_USE_MODAL)
  }

  showSupportModal() {
    this.$modal.show(constants.modalNames.NINTENDO_SUPPORT_MODAL)
  }
}
