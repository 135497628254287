






























import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import DeviceOption from '@/components/DeviceOption.vue'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import { Getter } from 'vuex-class'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { Shift } from '@/services/Shift'

@Component({
  components: {
    DeviceOption,
    BackDashboardButton
  }
})
export default class ClaimList extends Vue {
  @Getter('policy/policies')
  private policiesAvailableForClaims!: SwitchPolicyModel[]

  async claim(policy: SwitchPolicyModel) {
    if (policy.expired) {
      this.$modal.show(constants.modalNames.DEVICE_CLAIM_ERROR_MODAL)
    } else if (await Shift.checkOpenTasksByPolicy(policy.policyId) === 'FAIL') {
      this.$showErrorPopUp(this.$t('claimList.hasOpenTask').toString())
    } else if (policy.inStandDown) {
      this.$modal.show(constants.modalNames.DEVICE_IN_STAND_DOWN_ERROR_MODAL)
    } else if (policy.hasCurrentClaim) {
      this.$showErrorPopUp(this.$t('claimList.hasCurrentClaim').toString())
    } else {
      await this.$router.push({ name: constants.routeNames.CLAIM_DETAIL, params: { id: policy.policyId } })
    }
  }
}
