


































import { Component } from 'vue-property-decorator'
import constants from '@/constants'
import { ClaimOverlayView } from '@/views/mixins/ClaimOverlayView'
import Fact from '@/components/facts/Fact.vue'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { ACTION_VALIDATE_PRODUCT } from '@/store/modules/product/actionTypes'

@Component({
  components: {
    Fact
  }
})
export default class ClaimShipping extends ClaimOverlayView {
  ROUTE_NAME = constants.routeNames

  constructor() {
    super('shipping')
  }

  disableForward(): boolean {
    return this.validationErrors.length > 0
  }

  onFactChange(factChange: FactChangeEvent) {
    this.setFactValue(factChange)
  }

  get id() {
    return this.$route.params.id
  }

  created() {
    this.doCreated()
  }

  private async next() {
    await this.$store.dispatch(ACTION_VALIDATE_PRODUCT)
    if (!this.isViewValid) {
      return
    }
    await this.$router.push({ name: constants.routeNames.CLAIM_SUMMARY, params: { id: this.id } })
  }
}
