

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Checkbox'
})
export default class FluidCheckbox extends Vue {
  @Prop({ required: true }) private value!: boolean

  @Prop({ default: '' }) private name?: string

  @Prop({ default: '' }) private dataTc?: string

  @Prop({ default: false }) private disabled?: boolean

  @Prop({ default: false }) private id?: string

  get current(): boolean {
    return this.value
  }

  set current(value: boolean) {
    this.$emit('input', value)
  }
}
