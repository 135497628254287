




































import { Component } from 'vue-property-decorator'
import constants from '@/constants'
import { ClaimOverlayView } from '@/views/mixins/ClaimOverlayView'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import Fact from '@/components/facts/Fact.vue'
import { getModalContent } from '@/constants/modalContents'
import { InfoModalDescription } from '@/interfaces'
import { ACTION_VALIDATE_PRODUCT } from '@/store/modules/product/actionTypes'
import InfoModal from '@/components/modals/InfoModal.vue'

@Component({
  components: {
    InfoModal,
    Fact
  }
})
export default class ClaimDetail extends ClaimOverlayView {
  ROUTE_NAME = constants.routeNames

  private serialModal!: InfoModalDescription

  disableForward(): boolean {
    return this.validationErrors.length > 0
  }

  constructor() {
    super('repair')
  }

  onFactChange(factChange: FactChangeEvent) {
    this.setFactValue(factChange)
  }

  get id() {
    return this.$route.params.id
  }

  created() {
    this.doCreated()
    const modalInfo = getModalContent('photofront', this.$t.bind(this))
    if (!modalInfo) {
      throw Error('Could not get photofront modal.')
    }
    this.serialModal = modalInfo
  }

  private openInfo(e: Event) {
    e.preventDefault()
    this.$modal.show(this.serialModal.name)
  }

  private async next() {
    await this.$store.dispatch(ACTION_VALIDATE_PRODUCT)
    if (!this.isViewValid) {
      return
    }
    await this.$router.push({ name: constants.routeNames.CLAIM_SHIPPING, params: { id: this.id } })
  }
}
