












































import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import Fact from '@/components/facts/Fact.vue'
import { Getter } from 'vuex-class'
import { FnGetOverlayByName } from '@/store/modules/claim/getters'
import { FactDescription } from '@/interfaces/engine-exports'
import { shift } from '@uncharted/coverhub-framework'
import store from '@/store'
import { ACTION_CLAIM_SUBMIT } from '@/store/modules/claim/actionTypes'

import ValidationResponse = shift.claimengine.engine.validation.ValidationResponse
import NextButton from '@/components/layout/NextButton.vue'

@Component({
  components: {
    Fact
  }
})
export default class ClaimSummary extends Vue {
  @Getter('claim/getOverlayByName')
  private getOverlayByName!: FnGetOverlayByName

  private repairFacts!: FactDescription[]
  private shippingFacts!: FactDescription[]

  ROUTE_NAME = constants.routeNames

  get id() {
    return this.$route.params.id
  }

  renderer(fact: FactDescription): string {
    if (fact.id === 'device.type') {
      return 'DeviceTypeRenderer'
    }
    switch (fact.type.toString()) {
      case 'GROUP': return 'GroupRenderer'
      case 'MULTILINE_TEXT': return 'LargeTextRenderer'
      default: return 'TextRenderer'
    }
  }

  factValue(fact: FactDescription): string {
    switch (fact.type.toString()) {
      case 'LIST':
        return (
          fact.options.find((o) => o.value === fact.currentValue)?.name || ''
        )
      case 'BOOLEAN':
        return fact.currentValue === 'true'
          ? this.$t('button.yes').toString()
          : this.$t('button.no').toString()
      default:
        return fact.currentValue
    }
  }

  created() {
    const repair = this.getOverlayByName('repair')
    const shipping = this.getOverlayByName('shipping')
    if (repair && shipping) {
      this.repairFacts = repair.describeFacts().facts
      this.shippingFacts = shipping.describeFacts().facts
    } else {
      this.repairFacts = []
      this.shippingFacts = []
    }
  }

  async submitClaim() {
    try {
      const res: ValidationResponse = await store.dispatch(ACTION_CLAIM_SUBMIT)
      const fail = res.violations.find((v) => v.type === 'FAIL')
      if (!fail && res.valid) {
        this.$router.replace({ name: constants.routeNames.CLAIM_SUCCESS })
      } else {
        let message = ''
        if (res.errors.length > 0) {
          message = res.errors.map(e => `${e.message}`).join('. ')
        }
        if (res.violations.length > 0) {
          message = res.violations.map(v => `${v.message}`).join('. ')
        }
        this.$showErrorPopUp(message)
        const next = this.$refs?.next as NextButton
        next.reset()
      }
    } catch (e) {
      console.error(e)
      this.$router.push({ name: constants.routeNames.ERROR })
    }
  }
}
