


























import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component
export default class DeviceInStandDownErrorModal extends Vue {
  name = constants.modalNames.DEVICE_IN_STAND_DOWN_ERROR_MODAL

  onCancel () {
    this.$modal.hide(this.name)
  }

  async onConfirm () {
    this.$modal.hide(this.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
