




























import { Component, Vue } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import { Getter } from 'vuex-class'
import { SubmittedClaim } from '@/store/api/models/SubmittedClaim'
import Success from '@/components/Success.vue'

@Component({
  components: {
    DetailField,
    BackDashboardButton,
    Success
  }
})
export default class ClaimSuccess extends Vue {
  @Getter('claim/submittedClaim')
  claim!: SubmittedClaim

  @Getter('app/extraWaitingMessage')
  extraWaitingMessage!: boolean
}
