import { Vue } from 'vue-property-decorator'
import { FactDescription, ValidationError } from '@/interfaces/engine-exports'
import { FnGetOverlayByName } from '@/store/modules/claim/getters'
import { Getter } from 'vuex-class'
import { ClaimOverlay } from '@uncharted/coverhub-framework'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import store from '@/store'
import {
  ACTION_CLAIM_DESCRIBE_FACTS,
  ACTION_CLAIM_VALUE_SET
} from '@/store/modules/claim/actionTypes'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'

export class ClaimOverlayView extends Vue {
  /**
   * Gets coverage overlay
   */
  @Getter('claim/getOverlayByName')
  private getOverlayByName!: FnGetOverlayByName

  @Getter('claim/validationErrors')
  protected validationErrors!: ValidationError[]

  /**
   * Coverage overlay
   */
  protected overlay: ClaimOverlay | null = null

  /**
   * Product engine facts
   */
  @Getter('claim/facts')
  protected facts!: FactDescription[]

  /**
   * Maps component to a specific overlay.
   */
  private groupName: string

  /**
   * Holds a list of facts on page that are invalid
   */
  private invalidFacts: string[] = []

  /**
   * Sub-types should provide correct groupName.
   * @param groupName Maps to correct overlay
   */
  constructor(groupName: string) {
    super()
    this.groupName = groupName
    this.onFactValidation = this.onFactValidation.bind(this)
  }

  /**
   * Get coverage overlay so this component can render properly.
   */
  async doCreated() {
    this.overlay = this.getOverlayByName(this.groupName)
    await store.dispatch(ACTION_CLAIM_DESCRIBE_FACTS, this.groupName)
  }

  async setFactValue(evt: FactChangeEvent) {
    if (this.overlay) {
      await store.dispatch(ACTION_CLAIM_VALUE_SET, {
        groupName: this.groupName,
        factId: evt.id,
        value: evt.value
      } as FactValueChange)
    }
  }

  onFactValidation(evt: FactValidationEvent) {
    if (evt.valid) {
      const ix = this.invalidFacts.indexOf(evt.id)
      this.invalidFacts.splice(ix, 1)
    } else if (!this.invalidFacts.includes(evt.id)) {
      this.invalidFacts.push(evt.id)
    }
  }

  isViewValid(): boolean {
    return this.validationErrors.length === 0 && this.invalidFacts.length === 0
  }
}
